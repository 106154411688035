import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('module/leisure_subscriptions', {
            params: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`module/leisure_subscriptions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('module/leisure_subscriptions', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`module/leisure_subscriptions/${userData.id}`,
            userData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`module/leisure_subscriptions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    downloadDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/utilities_electricity/download/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`utilities_electricity/filedocument/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletefileData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/utilities_electricity/filedocument/+${id}')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchefamilymemberdetails(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/familymembers/relateddata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchesub(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/subscriptions/getalldata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchesubscriptionsub(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`subscriptionssub/${id}/getalldata`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}